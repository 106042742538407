import {Component, Input} from '@angular/core';
import {CheckBoxModule, SwitchModule} from "@syncfusion/ej2-angular-buttons";
import {TranslatePipe} from "../../pipes/translate.pipe";
import {FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";

@Component({
  selector: 'app-form-switch',
  standalone: true,
  imports: [
    SwitchModule,
    TranslatePipe,
    FormsModule,
    CheckBoxModule,
    ReactiveFormsModule
  ],
  templateUrl: './form-switch.component.html',
  styleUrl: './form-switch.component.scss'
})
export class FormSwitchComponent {

  @Input() fCN: string = '';
  @Input() fG: FormGroup = new FormGroup({});

}
