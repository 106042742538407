import {Injectable} from '@angular/core';
import {AnimationModel, AnimationSettingsModel, ResizeDirections} from "@syncfusion/ej2-angular-popups";

@Injectable({
    providedIn: 'root'
})
export class DialogService {
    private animationSettings: AnimationSettingsModel = {effect: 'Fade', duration: 400, delay: 0};
    private resizeHandleDirection: ResizeDirections[] = ['All'];
    private position: object = {X: 'center', Y: 'center'};
    private tooltipAnimation:  AnimationModel = { open: { effect: 'FadeIn' }, close: { effect: 'FadeOut' } };

    constructor() {
    }

    public getAnimationSettings(): AnimationSettingsModel {
        return this.animationSettings;
    }

    public getResizeHandleDirection(): ResizeDirections[] {
        return this.resizeHandleDirection;
    }

    public getPosition(): object {
        return this.position;
    }

    public getTooltipAnimation(): object {
        return this.tooltipAnimation;
    }
}
