import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DialogModule, TooltipModule} from "@syncfusion/ej2-angular-popups";
import {FormInputComponent} from "../../../shared/elements/form-input/form-input.component";
import {HeadlineComponent} from "../../../shared/elements/headline/headline.component";
import {TooltipComponent} from "../../../shared/elements/tooltip/tooltip.component";
import {TranslatePipe} from "../../../shared/pipes/translate.pipe";
import {DialogService} from "../../../shared/services/dialog.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ListViewModule} from "@syncfusion/ej2-angular-lists";
import {NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {CustomerService} from "../../../shared/services/customer.service";
import {LanguageService} from "../../../shared/services/language.service";
import {IconBadgeComponent} from "../../../shared/elements/icon-badge/icon-badge.component";
import {FormButtonComponent} from "../../../shared/elements/form-button/form-button.component";

@Component({
  selector: 'app-dialog-profile-language',
  standalone: true,
  imports: [
    DialogModule,
    FormInputComponent,
    HeadlineComponent,
    TooltipComponent,
    TranslatePipe,
    ListViewModule,
    NgIf,
    TooltipModule,
    NgForOf,
    NgOptimizedImage,
    IconBadgeComponent,
    FormButtonComponent
  ],
  templateUrl: './dialog-profile-language.component.html',
  styleUrl: './dialog-profile-language.component.scss'
})
export class DialogProfileLanguageComponent implements OnInit{
  @Input() visible: boolean = false;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public languages: any[] = [];
  public activeLanguage: string = this.languageService.getLanguage();
  public profileLanguageForm: FormGroup = this.formBuilder.group({
    searchString: [''],
  });

  constructor(
    public dialogService: DialogService,
    private formBuilder: FormBuilder,
    private languageService: LanguageService
  ) {
  }

  public ngOnInit(): void {
    this.languages = Object.keys(this.languageService.getLanguages());
    this.profileLanguageForm.get('searchString')?.valueChanges.subscribe((value: string) => {
      this.languages = Object.keys(this.languageService.getLanguages()).filter((language: string) => {
        return this.languageService.getTranslationByKey(language.toUpperCase()).toLowerCase().includes(value.toLowerCase());
      });
    });
  }

  public selectLanguage(language: string): void {
    this.activeLanguage = language;
  }

  public close(): void {
    this.visibleChange.emit(false);
  }

  public save(): void {
    this.languageService.setLanguage(this.activeLanguage);
    this.visibleChange.emit(false);
  }
}
