import {Component, Input} from '@angular/core';
import {TooltipModule} from "@syncfusion/ej2-angular-popups";
import {DialogService} from "../../services/dialog.service";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-tooltip',
  standalone: true,
  imports: [
    TooltipModule,
    NgIf
  ],
  templateUrl: './tooltip.component.html',
  styleUrl: './tooltip.component.scss'
})
export class TooltipComponent {

  @Input() text: string = '';
  @Input() cssClass: string = '';
  @Input() width: string = '';
  @Input() height: string = '';
  @Input() icon: string = '';
  @Input() opensOn: 'Click' | 'Hover' = 'Click';

  constructor(
    public dialogService: DialogService
  ) {
  }
}
