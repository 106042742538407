import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";

export function arrayValidator(validOptions: (string | number)[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const selectedValue = control.value;
    if (validOptions.includes(selectedValue)) {
      return null;
    }
    return {invalidArrayValue: true};
  };
}
