import {Component, OnInit} from '@angular/core';
import {FormButtonComponent} from "../../../shared/elements/form-button/form-button.component";
import {RouterService} from "../../../shared/services/router.service";
import {UserService} from "../../../shared/services/user.service";
import {NgForOf, NgIf} from "@angular/common";

@Component({
  selector: 'app-bar-icons',
  standalone: true,
  imports: [
    FormButtonComponent,
    NgForOf,
    NgIf
  ],
  templateUrl: './bar-icons.component.html',
  styleUrl: './bar-icons.component.scss'
})
export class BarIconsComponent implements OnInit {

  public iconsAllowed: string[] = [];

  constructor(
    public routerService: RouterService,
    private userService: UserService
  ) {
  }


  public ngOnInit(): void {
    this.iconsAllowed = this.userService.getActiveModules().concat(this.userService.getAdministrativeRights());
  }

}
