import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {DialogService} from "../../../shared/services/dialog.service";
import {LanguageService} from "../../../shared/services/language.service";
import {DialogModule} from "@syncfusion/ej2-angular-popups";
import {HeadlineComponent} from "../../../shared/elements/headline/headline.component";
import {TooltipComponent} from "../../../shared/elements/tooltip/tooltip.component";
import {TranslatePipe} from "../../../shared/pipes/translate.pipe";
import {FormButtonComponent} from "../../../shared/elements/form-button/form-button.component";
import {SwitchModule} from "@syncfusion/ej2-angular-buttons";
import {FormLabelComponent} from "../../../shared/elements/form-label/form-label.component";
import {FormSwitchComponent} from "../../../shared/elements/form-switch/form-switch.component";
import {Subscription} from "rxjs";
import {ApiService} from "../../../shared/services/api.service";
import {ToasterService} from "../../../shared/services/toaster.service";

@Component({
  selector: 'app-dialog-profile-push',
  standalone: true,
  imports: [
    DialogModule,
    HeadlineComponent,
    TooltipComponent,
    TranslatePipe,
    FormButtonComponent,
    SwitchModule,
    FormLabelComponent,
    FormSwitchComponent
  ],
  templateUrl: './dialog-profile-push.component.html',
  styleUrl: './dialog-profile-push.component.scss'
})
export class DialogProfilePushComponent implements OnInit, OnDestroy {
  @Input() visible: boolean = false;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public profilePushForm: FormGroup = this.formBuilder.group({
    push_disable_all: [''],
    push_disable_mobile: [''],
    push_disable_email: [''],
    push_disable_browser: [''],
    push_disable_chat: [''],
  });
  private subscriptions: Subscription[] = [];

  constructor(
    public dialogService: DialogService,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private toasterService: ToasterService,
    private languageService: LanguageService
  ) {
  }

  public ngOnInit(): void {
    this.load();
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  private load(): void {
    this.subscriptions.push(this.apiService.getProfile().subscribe((profile: any) => {
      this.profilePushForm.patchValue(profile);
    }));
  }

  public close(): void {
    this.visibleChange.emit(false);
  }

  public save(): void {
    this.subscriptions.push(this.apiService.setProfile(this.profilePushForm.value).subscribe(() => {
      this.toasterService.success(this.languageService.getTranslationByKey('PROFILE'), this.languageService.getTranslationByKey('PROFILESAVED'));
      this.visibleChange.emit(false);
    }));

  }
}
