<div class="icon-bar d-none d-md-flex flex-column align-items-center position-fixed border-top border-end border-bottom shadow p-m">
  <ng-container *ngFor="let icon of iconsAllowed">
    <app-form-button *ngIf="icon === 'intranet'" [style]="'btn'" [cssClass]="'btn-link p-s'" [iconClass]="'text-darkblue fs-5'" [icon]="'calendar_view_day'" (click)="routerService.navigateTo('/wall')"></app-form-button>
    <app-form-button *ngIf="icon === 'survey'" [style]="'btn'" [cssClass]="'btn-link p-s'" [iconClass]="'text-lightpurple fs-5'" [icon]="'cycle'" (click)="routerService.navigateTo('/feedback')"></app-form-button>
    <app-form-button *ngIf="icon === 'workflow'" [style]="'btn'" [cssClass]="'btn-link p-s'" [iconClass]="'text-pink fs-5'" [icon]="'rebase'" (click)="routerService.navigateTo('/workflow')"></app-form-button>
    <app-form-button *ngIf="icon === 'mapp'" [style]="'btn'" [cssClass]="'btn-link p-s'" [iconClass]="'text-orange fs-5'" [icon]="'psychology'" (click)="routerService.navigateTo('/mapp')"></app-form-button>
    <app-form-button *ngIf="icon === 'chat'" [style]="'btn'" [cssClass]="'btn-link p-s'" [iconClass]="'text-aqua fs-5'" [icon]="'chat'" (click)="routerService.navigateTo('/chat')"></app-form-button>
    <app-form-button *ngIf="icon === 'channel'" [style]="'btn'" [cssClass]="'btn-link p-s'" [iconClass]="'text-yellow fs-5'" [icon]="'forum'" (click)="routerService.navigateTo('/forum')"></app-form-button>
    <app-form-button *ngIf="icon === 'meeting'" [style]="'btn'" [cssClass]="'btn-link p-s'" [iconClass]="'text-blue fs-5'" [icon]="'videocam'" (click)="routerService.navigateTo('/meetings')"></app-form-button>
    <app-form-button *ngIf="icon === 'employee'" [style]="'btn'" [cssClass]="'btn-link p-s'" [iconClass]="'text-darkorange fs-5'" [icon]="'import_contacts'" (click)="routerService.navigateTo('/employees')"></app-form-button>
    <app-form-button *ngIf="icon === 'tv'" [style]="'btn'" [cssClass]="'btn-link p-s'" [iconClass]="'text-cyan fs-5'" [icon]="'tv'" (click)="routerService.navigateTo('/tv')"></app-form-button>
    <app-form-button *ngIf="icon === 'profile'" [style]="'btn'" [cssClass]="'btn-link p-s'" [iconClass]="'text-purple fs-5'" [icon]="'perm_contact_calendar'" (click)="routerService.navigateTo('/profile')"></app-form-button>
    <app-form-button *ngIf="icon === 'settings_users'" [style]="'btn'" [cssClass]="'btn-link p-s'" [iconClass]="'text-darkgreen fs-5'" [icon]="'person'" (click)="routerService.navigateTo('/admin/users')"></app-form-button>
    <app-form-button *ngIf="icon === 'settings_groups'" [style]="'btn'" [cssClass]="'btn-link p-s'" [iconClass]="'text-skyblue fs-5'" [icon]="'group'" (click)="routerService.navigateTo('/admin/groups')"></app-form-button>
    <app-form-button *ngIf="icon === 'general'" [style]="'btn'" [cssClass]="'btn-link p-s'" [iconClass]="'text-red fs-5'" [icon]="'settings'" (click)="routerService.navigateTo('/general')"></app-form-button>
    <app-form-button *ngIf="icon === 'document'" [style]="'btn'" [cssClass]="'btn-link p-s'" [iconClass]="'text-brightgreen fs-5'" [icon]="'folder'" (click)="routerService.navigateTo('/documents')"></app-form-button>
    <app-form-button *ngIf="icon === 'calendar'" [style]="'btn'" [cssClass]="'btn-link p-s'" [iconClass]="'text-asphalt fs-5'" [icon]="'calendar_month'" (click)="routerService.navigateTo('/calendar')"></app-form-button>
  </ng-container>
</div>
