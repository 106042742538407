import {Component, Input} from '@angular/core';
import {TranslatePipe} from "../../pipes/translate.pipe";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-form-label',
  standalone: true,
  imports: [
    TranslatePipe,
    NgIf
  ],
  templateUrl: './form-label.component.html',
  styleUrl: './form-label.component.scss'
})
export class FormLabelComponent {

  @Input() label: string = '';
  @Input() maxChars: number = 0;
  @Input() currentChars: number = 0;
  @Input() type: 'top'|'content' = 'top';

}
